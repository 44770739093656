<template>
  <div id="footer" :class="$isBrowser ? `browser-footer` : `app-footer`">
   <div class="footer-inner">
    <div class="col logo-col">
      <img loading="lazy" class="logo" alt="Carllective logo" width="100" height="50" src="../assets/Carllective Wht.png"/>
    </div>

    <ul class="col">
      <li>
        <router-link to="/">{{lang === "en" ? 'Home' : 'Accueil'}}</router-link>
      </li>
      <li>
        <router-link :to="lang === 'en' ? '/calendar' : '/calendrier'">{{lang === "en" ? 'Calendar' : 'Calendrier'}}</router-link>
      </li>
      <li>
        <router-link to="/map">Map</router-link>
      </li>
      <li>
        <router-link to="/shop">Shop</router-link>
      </li>
      <!-- <li>
        <div class="langs">
          <small><a class="langs_a" href="https://carllective.ca">EN</a></small><span>/</span>
          <small><a class="langs_a" href="https://fr.carllective.ca">FR</a></small>
          <img loading="lazy" src="@/assets/Cart.svg" class="cart"/>

        </div>
      </li> -->
      
  </ul>

  <ul class="col">
    <li>
      <router-link to="/privacy-policy">
        Privacy Policy
      </router-link>
    </li>
    <li>
     <router-link to="/terms-of-service">
      Terms of Service
     </router-link>
    </li>
  </ul>

  <ul class="col">
    <li>
      <a href="https://www.instagram.com/carllective.ca" target="_blank">
        Instagram
      </a>
    </li>
    <li>
      <a href="https://www.facebook.com/carllective" target="_blank">
        Facebook
      </a>
    </li>
    <li class="submitevent" @click="$emit('toggleShowSubmitForm')">
        <a v-show="lang==='en'">Submit Event!</a>
        <a v-show="lang==='fr'">Soumission d'Événement!</a>
      </li>
  </ul>
    <!-- <a class="button signup" href="http://eepurl.com/h7ok6D" target="_blank">{{footerCTA}}</a> -->
    <p v-if="lang === 'en'" class="copyright">© Copyright {{year()}} Carllective. All Rights Reserved. <br/>Founded by 
      <a href="https://www.rebj.ca" target="_blank">Design By Reb J.</a>
    </p>
    <p v-else class="copyright">© Copyright {{year()}} Carllective. Tous droits reservés. </p>
   <!-- <p>{{lang === "en" ? 'Developed by ' : 'Crée par '}}<a href="https://www.instagram.com/designbyrebj" target="_blank">@designbyrebj</a></p> -->
   </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'FooterArea',
  props: {
    deviceType: {
      type: String,
      default: "desktop"
    }
  },
  components: {
  },
  watch: {

  },
  computed: {
    ...mapState(["lang"])
  },
  methods: {
    year() {
      return new Date().toString().split(" ")[3];
    }
  },
  data() {
    return {
      footerCTA: "SIGN UP TO BE INFORMED OF THE UPCOMING APP LAUNCH!"
    }
  },
  mounted() {
    if (this.$CTA) this.footerCTA = this.$CTA;
  }
  
}
</script>

<style lang="scss" scoped>
@import "../styles.scss";
#footer {
  min-height: 10vh;
  margin: auto;
  // background: #2F2F2F;
  background: rgb(37,37,37);
  background: linear-gradient(0deg, rgb(66, 66, 66) 0%, rgba(0,0,0,1) 100%);
  position: relative;
  padding: 20px;

  @media screen and (max-width: 999px) {
    padding: 20px 10px 40px;
  }

  .copyright {
    @media screen and (max-width: 999px) {
      text-align: left;
    }
    a {
      font-size: $size-smalltext !important;
    }
  }


  .signup {
    padding: 20px;
    max-width: 400px;
    margin: 20px auto;
    background: $highlightcol;
    text-align: center;
    display: block;
    font-family: "Header";
  }
  p {
    margin: 0;
    text-align: center;
    font-size: $size-smalltext;
    padding: 5px 0;
  }

  .footer-inner {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    
    a {
      font-size: $size-medtext;
      font-weight: bold;  
    }
    p {
      padding: 0;
    }
    li {
      font-size: $size-medtext;
      list-style: none;
      margin-bottom: 20px;
    }
  }
}
.app-footer {
  padding-bottom: 200px !important;
}

.col {
  margin: 0;
  display: inline-block;
  vertical-align: top;

  @media screen and (min-width: 1000px) {
    width: calc(50% / 3);
    padding: 10px 0 40px;
    .submitevent {
      padding-top: 30px;
    }
  }
  @media screen and (max-width: 999px) {
    width: 100%;
    padding: 5px 0 10px;
  }

}
.logo-col {
  width: 50%;

  .logo {
      min-width: 200px;
      @media screen and (max-width: 999px) {
       min-width: 150px;
       padding-bottom: 20px;
      }
    }
}
</style>
