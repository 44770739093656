<template>
  <div id="app-outer">
    <div id="app" :class="lang === 'fr' ? 'fr' : 'en'">
    <transition appear name="refresh">
      <!-- <video class="refresh" v-show="showRefresh" muted playsinline autoplay loop>
        <source src="~@/assets/C.webm">
        </video> -->
        <img 
        class="refresh"
        v-show="showRefresh"
        src="~@/assets/C-Wheel-White-Transparent.gif"/>
      </transition>

      <div class="app_logobar" v-if="!$isBrowser">
        <ul>
            <router-link to="/" class="mobile_a">
              <li>
                <!-- Home -->
                <img loading="lazy" src="./assets/home.svg"/>
              </li>
            </router-link>

            <router-link class="mobile_a" to="/shop">
              <li>
              
                <!-- {{lang === "en" ? 'Shop' : 'Magasin'}} -->
                <img loading="lazy" src="./assets/store.svg"/>
              </li>
            </router-link>

            <router-link class="mobile_a" :to="lang === 'en' ? '/calendar' : '/calendrier'">
              <li>
                <!-- {{lang === "en" ? 'Calendar' : 'Calendrier'}} -->
              <img loading="lazy" src="./assets/calendar.svg"/>
              </li>
            </router-link>
            <router-link to="/map" class="mobile_a">
              <li>
                <img loading="lazy" src="./assets/map.svg"/>
              </li>
            </router-link>
          <!-- <li class="coming_soon">
            {{lang === "en" ? 'Settings' : 'Réglages'}}
          </li> -->
          <li class="submitevent" @click="showSubmitForm = !showSubmitForm">
              <a v-html="lang === 'en' ? `Submit Event` : `Soumission d'événement`"></a>
          </li>
        </ul>
      </div>

      <transition appear name="fadein">
        <div class="mobile hamburger-menu" v-show="showHamburgerMenu" v-if="$isBrowser">
          <ul class="nav">
            <li>
            <router-link to="/">{{lang === "en" ? 'Home' : 'Accueil'}}</router-link>
            </li>
            <li>
            <router-link :to="lang === 'en' ? '/calendar' : '/calendrier'">{{lang === "en" ? 'Calendar' : 'Calendrier'}}</router-link>
            </li>
            <li>
              <router-link to="/map">Map</router-link>
            </li>
            <li>
              <router-link to="/shop">Shop</router-link>
            </li>
            <li class="submitevent" @click="showSubmitForm = !showSubmitForm">
              <a v-show="lang==='en'">Submit Event!</a>
              <a v-show="lang==='fr'">Soumission d'Événement!</a>
            </li>
            <!-- <li>
              <div class="langs">
                <small><a class="langs_a" href="https://carllective.ca">EN</a></small><span>/</span>
                <small><a class="langs_a" href="https://fr.carllective.ca">FR</a></small>
                <img loading="lazy" src="@/assets/Cart.svg" class="cart"/>

              </div>
            </li> -->
                  
            </ul>
        </div>
      </transition>

      <div class="logobar">
          <div :class="`mobile readable_location_outer ${!$store.state.readable_location ? `nolocation` : ``}`">
            <router-link to="./map">
              <span class="readable_location">
                <img loading="lazy" width="15" src="@/assets/Location white.svg">
                <small v-if="$store.state.readable_location">
                  {{ $store.state.readable_location }}
                </small>
                <small v-else>
                  Location loading...
                </small>
              </span>
            </router-link>
          </div>
          <ul class="logobar-inner">
            
            <div class="back-nav mobile" >
              <p class="back-nav-inner" v-if="!onHomePage()" v-show="!showHamburgerMenu">
                <router-link to="/">
                  <span class="chevron">‹</span>
                  <span class="back">Back </span>
                </router-link>
              </p>
              <!-- <span class="readable_location" v-else>
                <img loading="lazy" width="20" src="@/assets/Location white.svg"><small>{{ $store.state.readable_location }}</small>
              </span> -->
            </div>
            <li class="logoli">
              <router-link to="/" class="logolink">
                <img loading="lazy" class="logo" alt="Carllective logo" width="100" height="50" src="./assets/Carllective Wht.png"/>
                <!-- <img loading="lazy" class="logo mobile" alt="Carllective logo" width="50" height="50" src="./assets/C Carllective Logo White.svg"/> -->
              </router-link>
            </li>
            <li :class="`desktop readable_location_outer ${!$store.state.readable_location ? `nolocation` : ``}`">
              <router-link to="./map">
                <span class="readable_location">
                  <img loading="lazy" width="20" height="25" src="@/assets/Location white.svg">
                  <small v-if="$store.state.readable_location">
                    {{ $store.state.readable_location }}
                  </small>
                  <small v-else>
                    Location loading...
                  </small>
                </span>
              </router-link>
            </li>
            <ul class="desktop nav">
                <li>
                <router-link to="/">{{lang === "en" ? 'Home' : 'Accueil'}}</router-link>
                </li>
                <li>
                <router-link :to="lang === 'en' ? '/calendar' : '/calendrier'">{{lang === "en" ? 'Calendar' : 'Calendrier'}}</router-link>
                </li>
                <li>
                  <router-link to="/map">Map</router-link>
                </li>
                <li>
                  <router-link to="/shop">Shop</router-link>
                </li>
                <li class="submitevent" @click="showSubmitForm = !showSubmitForm">
                  <a v-show="lang==='en'">Submit Event!</a>
                  <a v-show="lang==='fr'">Soumission d'Événement!</a>
                </li>
                <!-- <li>
                  <div class="langs">
                    <small><a class="langs_a" href="https://carllective.ca">EN</a></small><span>/</span>
                    <small><a class="langs_a" href="https://fr.carllective.ca">FR</a></small>
                    <img loading="lazy" src="@/assets/Cart.svg" class="cart"/>

                  </div>
                </li> -->
                
            </ul>

            <div class="hamburger-menu-icon mobile">
            <transition appear name="fadein">
                <img loading="lazy" v-if="$isBrowser" class="hamburger-icon" v-show="!showHamburgerMenu" src="./assets/Hamburger.svg" @click="showHamburgerMenu = !showHamburgerMenu"/>
            </transition>
            <transition appear name="fadein">
              <img loading="lazy" v-if="$isBrowser" class="hamburger-icon" v-show="showHamburgerMenu" src="./assets/Hamburger-close.svg" @click="showHamburgerMenu = !showHamburgerMenu"/>
            </transition>
            </div>
                
            <!-- <div class="langs mobile">
              <img loading="lazy" src="@/assets/Cart.svg" class="cart"/>

                        
              <small><a href="https://carllective.ca">EN</a></small><span>/</span>
              <small><a href="https://fr.carllective.ca">FR</a></small>

            </div> -->
        </ul>

      </div>
    
        
      <div class="appinner" id="appinner">
    <!-- <p style="position: fixed;
    z-index: 3;"> {{ windowscroll }}</p> -->
      <!-- <button @click="doimageCompress" style="position: fixed; background: white; color: black; z-index: 100;"> Image Compress</button> -->
      <!-- <transition appear name="loading" :key="loading">
        <div class="loading" v-if="loading">
          <img loading="lazy" class="loadingimage" alt="Carllective loading animation" src="./assets/C.gif"/>
        </div>
      </transition> -->
      <!-- <div id="copyMeetsTodayText" @click="copyMeetsTodayText"></div> -->
      <!-- <div id="copyMeetsTomorrowText" @click="copyMeetsTomorrowText"></div> -->

    
      <transition appear name="fadein">
      <div class="submitForm" v-if="showSubmitForm && lang === 'en'">
        <div class="submitForm-inner">
          <div class="close" @click="showSubmitForm = false"><h2>X</h2></div>
              <h2>Submit a Car Meet, Automotive Event, or Car Expo/Show!</h2>
              <form @submit.prevent="submitForm" ref="form" v-if="!successMessage">
                <label><p>Event Name <span>*</span></p></label>
                <input required ref="name" type="text"/>
                <br/>
                <label><p>Event Date <span>*</span></p></label>
                <input required ref="date" type="text"/>
                <br/>
                <label><p>Instagram @</p></label>
                <input ref="ig" type="text"/>
                <br/>
                <br/>
                <label><p>Website / Original Post URL <span>*</span></p></label>
                <input required ref="website" type="text"/>
                <br/>
                <label><p>Poster Image <span>*</span></p></label>
                <input required type="file" id="img" name="img" ref="img" accept="image/*"/>

              <p class="captchaMessage" v-if="captchaMessage">{{captchaMessage}}</p>


                <VueRecaptcha 
              class="recaptcha"
              sitekey="6LdtG00qAAAAAJtStfPB45KH6g5LfmPTOqV6nZ3L"
              @verify="verifyMethod"
              @expired="errorMethod"
              @error="errorMethod"
              ></VueRecaptcha>

                <input class="button submitbutton" type="submit"/>
              </form>
              <p class="successMessage" v-if="successMessage">{{successMessage}}</p>

              
              </div>
      </div>
      <!-- French form -->
      <!-- <div class="submitForm" v-else-if="showSubmitForm && lang === 'fr'">
        <div class="submitForm-inner">
        <div class="close" @click="showSubmitForm = false"><h2>X</h2></div>
        <h2>Soumettez un rencontre, un spectacle, ou un événement de voiture!</h2>
        <form @submit.prevent="submitForm" ref="form" v-if="!successMessage">
          <label><p>Nom <span>*</span></p></label>
          <input required ref="name" type="text"/>
          <br/>
          <label><p>Date <span>*</span></p></label>
          <input required ref="date" type="text"/>
          <br/>
          <label><p>Instagram @</p></label>
          <input ref="ig" type="text"/>
          <br/>
          <br/>
          <label><p>Site-web ou lien original <span>*</span></p></label>
          <input required ref="website" type="text"/>
          <br/>
          <label><p>Image d'affiche</p></label>
          <input type="file" id="img" name="img" ref="img" accept="image/*"/>
          <input class="button submitbutton" type="submit"/>
        </form>
        <p class="successMessage" v-if="successMessage">{{successMessage}}</p>
        <p class="captchaMessage" v-if="captchaMessage">{{captchaMessage}}</p>

      </div>
        <VueRecaptcha sitekey="6LdtG00qAAAAAJtStfPB45KH6g5LfmPTOqV6nZ3L"></VueRecaptcha>
      </div> -->
      
    </transition>
      <router-view 
        :clickObj="clickObj"
        :scroll="scroll"
        :deviceType="deviceType"
        @showDropdown="handleshowDropdown"
        class="view">
      </router-view>
      </div>
      <FooterArea 
      @toggleShowSubmitForm="showSubmitForm = !showSubmitForm"
      :deviceType="deviceType"/>
    </div>
  </div>
</template>

<script>
import FooterArea from './components/FooterArea.vue'
import {mapState} from "vuex";
import Airtable from "./airtable.js";
import Email from "./smtp.js";
import ImageCompressor from "js-image-compressor";
import airtable from './airtable';
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  name: 'App',
  components: {
    FooterArea,
    VueRecaptcha
  },
  watch: {
    // ONLY ENABLE WHEN YOU KNOW HOW TO DO THE LAZY LOAD
    $route() {
      this.disableRefreshScroll = this.$route.path !== "/" && this.$route.path !== "#/";
      
        this.$nextTick(() => {
          // this needs to change
          if (this.$route.path === "/" || (this.$route.path === "#/")) {
            // document.getElementById("app").scrollTo(0, this.meetListScrollY);
            window.scrollTo({ top: this.meetListScrollY, left: 0 });
          }
          else window.scrollTo(0, 0);
        })

      this.showHamburgerMenu = false;
      this.showSubmitForm = false;
      this.removeEventListeners();
      this.$nextTick(() => {
        this.addEventListeners();
      })
    },
    showRefresh() {

      if (this.showRefresh) {
          this.disableRefreshScroll = true;
          window.removeEventListener("touchstart", this.touchstart);
          document.querySelector("body").style.height = `100vh`;
          document.querySelector("body").style.overflow = `hidden`;
          document.getElementById("appinner").style.paddingBottom = `80px`;

          this.$nextTick(() => {
            this.$geoAPI.fetchLocation2();

            Promise.all([
              airtable.init_airtable2(), 
              airtable.init_shop(),
              airtable.init_shop_items(),
              airtable.init_featured(),
              airtable.init_airtable(),
              airtable.init_banner(),
              airtable.init_ads(),


            ]).then(() => {
              this.showRefresh = false;
              document.querySelector("body").style.height = `auto`;
              document.querySelector("body").style.overflow = `scroll`;
  
              document.getElementById("appinner").style.transition = `transform .5s ease`;
              document.getElementById("appinner").style.transform = `translate3d(0px,0px,0px)`;
              document.getElementById("appinner").style.transform = null;
              document.getElementById("appinner").style.paddingBottom = `0px`;

              window.addEventListener("touchstart", this.touchstart);

              setTimeout(() => {
                if (this.$route.path === "/" || this.$route.path === "#/") this.disableRefreshScroll = false; 
              }, 1000)
            })
            
            // window.location.reload();
          })
      }
    }
  },

  computed: {
    ...mapState(["loading", "lang", "meetsLoadedIndex", "maxMeetsLoaded", "filteredMeets", "meetListScrollY"])
  },
  methods: {
    verifyMethod() {
      this.captchaverified = true;
    },
    errorMethod() {
      
      this.captchaMessage = "The token is invalid. Please try again.";
      this.captchaverified = false;

      setTimeout(() => {
        this.successMessage = null;
      }, 5000);
    },
    handleshowDropdown(e) {
      this.showDropdown = e;
      if (this.showDropdown) window.removeEventListener("touchstart", this.touchstart);
      else window.addEventListener("touchstart", this.touchstart);

    },
    onHomePage() {
      // console.log( window.location)
      return ((window.location.hash === `#/` || window.location.hash === ``) 
      && (window.location.pathname === `/` || window.location.host === ``))
      || (window.location.pathname === "/index.html" && (window.location.hash === `#/`)) // For android
      || (window.location.hash === `#/map` || window.location.pathname === `/map`)
      || (window.location.hash === `#/calendar` || window.location.pathname === `/calendar`) 
      || (window.location.hash === `#/shop` || window.location.pathname === `/shop`) ;
    },
    scrollEvent(e) {
      this.scroll = e;
      // let windowPos = document.getElementById("app").scrollTop;
      let windowPos = window.pageYOffset || window.scrollTop;
      // console.log(window.pageYOffset)
      
      if (this.$route.path === "/") this.$store.commit("setMeetListScrollY", windowPos);
    },
    onKeyDown(e) {

      if (this.keysDown.indexOf(e.keyCode) > -1) return;
      this.keysDown.push(e.keyCode)

      if (this.keysDown.indexOf(70) > -1 && (this.keysDown.indexOf(91) > -1 || this.keysDown.indexOf(17) > -1)) {
        e.preventDefault();
        document.getElementById("search").focus();
      }
    },
    touchstart(e) {
      // console.log( window.pageYOffset)
      document.getElementById("appinner").style.transition = `none`;

      if (this.showRefresh || this.disableRefreshScroll)  return;
      
      this.touchstartPos = e.touches[0].clientY;

      
      if ((window.scrollTop < 1 || window.pageYOffset  < 1) && !this.showRefresh) {
        window.addEventListener("touchmove", this.touchmove);
        window.addEventListener("touchend", this.touchend); 
      }
    },
    touchmove(e) {
      if (this.showRefresh || this.disableRefreshScroll)  return;

      let appinnerMove = Math.min((Math.min((e.touches[0].clientY - this.touchstartPos), window.innerHeight / 8)), 80);
      // if direction is up AND you are already at the top
      if (e.touches[0].clientY > this.lastTouch && (window.scrollTop < 1 || window.pageYOffset  < 1)) {
        document.getElementById("appinner").style.transform = `translate3d(0,${appinnerMove}px,0)`;
        document.getElementById("appinner").style.transition = `none`;

        this.divTransform = parseInt(document.getElementById("appinner").style.transform.split("translate3d(0px, ")[1].split("px")[0]);
        // if you pull back up and you're at the top
        if (this.divTransform >= 80) {
          this.showRefresh = true;
        }
      }

      this.lastTouch = e.touches[0].clientY;

    },
    touchend() {
      if (this.showRefresh || this.disableRefreshScroll)  return;
      if (this.divTransform < 80) {
        document.getElementById("appinner").style.transition = `transform .5s ease`;
        document.getElementById("appinner").style.transform = `translate3d(0px,0px,0px)`;
        document.getElementById("appinner").style.transform = null;

        document.getElementById("appinner").style.paddingBottom = `0px`;
      }
      // this.showRefresh = false;
      window.removeEventListener("touchmove", this.touchmove);
      window.removeEventListener("touchend", this.touchend); 
    },
    removeEventListeners() {
      window.removeEventListener("mousewheel", this.scrollEvent);
      window.removeEventListener("scroll", this.scrollEvent);
    },
    addEventListeners() {
      this.deviceType = (() => {
        if (window.navigator.userAgent.includes("iPhone") ||
        window.navigator.userAgent.includes("Mobile")) {
          return "mobile";
        }
        return "desktop";
      })();

      window.addEventListener("click", (e) => {this.clickObj = e});

      // On Home page
      if (window.location.pathname === "/" || window.location.hash === "#/") {
        switch(this.deviceType) {
          case "mobile": {
            window.addEventListener("scroll", this.scrollEvent);
            window.addEventListener("touchstart", this.touchstart);
            break;
          }
          case "desktop": {
            window.addEventListener("mousewheel", this.scrollEvent);
            window.onkeydown = this.onKeyDown;
            window.onkeyup = () => {this.keysDown = [];}
            if (document.getElementById("search")) {
                document.getElementById("search").onfocus = () => {
                document.querySelector("body").style.height = "100vh";
                document.querySelector("body").style.overflowY = "hidden";
                document.getElementById("searchActiveScreen").style.opacity = "1";
                document.getElementById("searchActiveScreen").style.pointerEvents = "auto";
              }
              document.getElementById("search").onblur = () => {
                document.getElementById("searchActiveScreen").style.opacity = 0;
                document.getElementById("searchActiveScreen").style.pointerEvents = "none";
                document.querySelector("body").style.height = "auto";
                document.querySelector("body").style.overflowY = "";
              }
            }
            
            break;
          }
        }
       
      } 
    },
    doimageCompress() {
      let i = 0;
      setInterval(() => {
        this.imageCompress(i);
        i++
      }, 1000);
    },
    imageCompress(i) {
        let url = [...this.$meets][i].Image[0].url;
        let id = [...this.$meets][i].id;
        // console.log(id, url);

        fetch(url).then((res) => {
          return res.blob();
        }).then((blob) => {
          const file = new File([blob], 'image.jpeg', {type: blob.type});
          // console.log(meet.Image[0].url);
          // let file = meet.Image[0].url;
          var options = {
            file: file,
            quality: 1,
            maxWidth: 700,
            maxHeight: 700,

            // Callback before compression
            beforeCompress: function (image) {
              console.log('Image size before compression:', image.size);
              console.log('mime type:', image.type);
            },

            // Compression success callback
            success: function (image) {
              console.log('result:', image)
              console.log('Image size after compression:', image.size);
              console.log('mime type:', image.type);
              console.log('Actual compression ratio:', ((file.size-image.size) / file.size * 100).toFixed(2) +'%');
              Airtable.uploadImageToField(image, id);
            }
          };

          new ImageCompressor(options);
        });
    },
    copyMeetsTodayText() {
      let date = new Date().toString().split(" ");
      let text = `EVENTS on ${date[0].toUpperCase()}. ${date[1]} ${date[2]}, ${date[3]}: \n \n`;
      [...this.$meets].filter(i => i.Today).forEach(meet => {
        text += `MEET: ${meet.Name}
WHERE: ${meet.Address}
TIME: ${meet.Time}
\n`;
      });
      navigator.clipboard.writeText(text);
    },
    copyMeetsTomorrowText() {
      let text = `EVENTS on  \n \n`;
      [...this.$meets].filter(i => i.Tomorrow).forEach(meet => {
        text += `MEET: ${meet.Name}
WHERE: ${meet.Address}
TIME: ${meet.Time}
\n`;
      });
      navigator.clipboard.writeText(text);
    },
    async submitForm() {
      if (!this.captchaverified) return;

      new Promise((resolve) => {
        if (this.$refs.img.files[0]) {
          var axios = require('axios');
          var fileinput = this.$refs.img.files[0];
          const formData = new FormData();
          formData.append( "image", fileinput ); // has to be named 'image'!
          axios.post( 'https://api.imgbb.com/1/upload?key=84c270b8a531c47fb4ec646f3adaeb93', formData )
            .then( res => { 
              resolve(res.data.data.display_url);
            } )
        } else {
          resolve('');
        }
      }).then((imgUrl) => { 
        
        // var submission = {
        //   name: this.$refs.name.value,
        //   date: this.$refs.date.value,
        //   ig: this.$refs.ig.value,
        //   website: this.$refs.website.value,
        //   img: imgUrl
        // }
        Email.send({
          SecureToken : "f576814f-285b-4ce7-9ae9-1d2f35fc62ca",
          To : 'rebjanec@gmail.com',
          From : "carllective@rebj.ca",
          Subject : "carllective.ca submission",
          Body : `
          Name: ${this.$refs.name.value} <br/>
          Date: ${this.$refs.date.value} <br/>
          Instagram: ${this.$refs.ig.value} <br/>
          Website: ${this.$refs.website.value} <br/>
          Image: <img loading="lazy" src="${imgUrl}"/>
          `
        }).then((status) => {
          console.log(status)
          if (status === "OK") {
            this.successMessage = "Thank you! Your submission has been sent successfully";
            this.$refs.form.reset();
          } else {
            window.alert("Something went wrong. Please try again.")
          }
          
          // Airtable.submitEvent(submission);
          
          setTimeout(() => {
            this.successMessage = null;
            this.showSubmitForm = false;
          }, 5000);
        })
        

      })
    }
  },
  data() {
    return {
      scroll: null,
      showSubmitForm: false,
      successMessage: null,
      captchaMessage: null,
      disableRefreshScroll: true,
      touchstartPos: 0,
      showRefresh: false,
      lastTouch: null,
      showDropdown: false,
      showHamburgerMenu: false,
      divTransform:0,
      captchaverified: false,
      mobileBrowsers: 
        ['Safari', 
        'Chrome', 
        'Firefox', 
        'Edge',
        'Yandex.Browser', 
        'Samsung Browser', 
        'Opera Mobile', 
        'MIUI Browser', 
        'Huawei Browser', 
        'Phoenix', 
        'UC Browser',
        'VivoBrowser',
        'HeyTap Browser',
        'Amazon Silk',
      ],
      deviceType: null,
      keysDown: [],
      clickObj: null
    }
  },
  mounted() {
    this.$store.commit("setMeetListScrollY", 0);
  }
}
</script>

<style lang="scss">
@import "./styles.scss";

.recaptcha {

}

.refresh {
  width: 75px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100px;
  // filter: invert(1);
  // -webkit-filter: invert(1);
  // mix-blend-mode: exclusion;
  pointer-events: none;
  transition: opacity .25s ease;
  opacity: 1;
  z-index: 3;
}
.refresh-enter, .refresh-leave-to {
  transition: opacity .25s ease;
  opacity: 0;
}

#copyMeetsTodayText {
  width: 50%;
  height: 10px;
  position: fixed;
  top: 0;
  right: 50%;
  z-index: 20;
}
#copyMeetsTomorrowText {
  width: 50%;
  height: 10px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 20;
}
* {
  -webkit-font-smoothing: antialiased;
}

.logo {
    height: auto;
    max-width: 150px;
    min-width: 125px;
    @media screen and (max-width: 999px) {
      width: 100%;
      // max-width: 30px;
      // min-width: 30px;
      max-width: 80px;
      min-width: 80px;
      padding-top: 7px;
      
    }
}

.logobar-inner {
  margin: auto 0;
  position: relative;
  text-align: center;
  display: flex;
  width: 100%;
  padding: 10px 0;

  @media screen and (max-width: 999px) {
    padding: 5px 0;
  }

  @media screen and (min-width: 1000px) {
    max-width: 1200px;
    width: calc(100% - 20px);
    margin: auto;
  }

  a, li {
    display: inline-block;
    vertical-align: middle;
  }
  .logoli {
    display: inline-block;
    text-align: left;
    padding-left: 0;

    a {
      width: 100%;
    }
    @media screen and (max-width: 999px) {
      // width: calc(100% / 3);
      width: 100%;
      // margin-left: auto;
    }
  }
  @media screen and (max-width: 999px) {
    text-align: left;
    li {
      // width: calc(50% - 20px);
      width: 20%;
      text-align: left;
      display: inline-block;
      padding: 0;
      // margin-left: 10px;
    }
  }
}
.logobar {
  position: fixed;
  transform: translate3d(0,0,0);
  text-align: center;
  // background: #2F2F2F;
  background: black;
  background: linear-gradient(0deg, rgb(31, 31, 31) 0%, rgba(0,0,0,1) 100%);

  // padding: 10px 0;
  // width: calc(100% - 4vw);
  top: 0;
  left: 0;
  z-index: 10;
  // position: relative;
  -webkit-box-shadow: 0px 9px 14px 0px rgba(0,0,0,0.2); 
  box-shadow: 0px 9px 14px 0px rgba(0,0,0,0.2);
  // padding-left: 2vw;
  // padding-right: 2vw;
  width: 100vw;

  .nav {
    // position: absolute;
    min-width: 380px;
    text-align: center;
    margin: auto 0 auto auto;
    // border-left: 1px solid white;
    // margin-top: 10px;
    top: 50%;
    // transform: translateY(-50%);
    // width: 100%;
    display: inline-block;
  }
}

ul {
  padding: 0;
  li {
    padding: 0 10px;
    position: relative;
  }
  a {
    font-family: "Body";
    text-decoration: none;
    font-size: $size-medtext;
  }
}

.left {
  left: 0;
  text-align: right;
}
.right {
  right: 0;
  text-align: left;
}
.desktop {
  @media screen and (max-width: 999px) {
    display: none !important;
  }
}
.app_logobar {
  -webkit-box-shadow: 0px -7px 14px 0px rgba(0,0,0,0.2); 
  box-shadow: 0px -7px 14px 0px rgba(0,0,0,0.2);
  font-size: 10px;
  background: rgb(232, 232, 232);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 11;
  border-radius: 15px 15px 0 0;
  padding-bottom: 15px;
  // transform: translate3d(0,98vh,0);

  .mobile_a {
    // position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
    border-radius: 10px;
    &:before, &:after {
      content: none !important;
    }
  }
  img {
    // width: 25%;
    width: 30%;
    max-width: 30px;
    pointer-events: none;
    z-index: 2;
    position: relative;
  }
  a {
    text-decoration: none;
  }
  @media screen and (min-width: 1000px) {
    display: none;
  }
  ul {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    width: 100%;
    text-align: center;
    justify-content: center;
    display: inline-block;
    margin: 0;
    // transform: translateY(-100%);
    // background: rgb(232, 232, 232);
    // border-radius: 15px 15px 0 0;
    z-index: 11;

    li {
      display: inline-block;
      vertical-align: middle;
      padding: 10px 0;
      width: calc(20% - 1px);
      // &:not(:last-child) {
      //   border-right: 1px solid grey;
      // }
    }
   
  }
}
.coming_soon {
  text-decoration: line-through;
  &:after {
    content: "Coming Soon";
    position: absolute;
    left: 50%;
    font-size: 12px;
    top: 100%;
    width: 100%;
    text-align: center;
    transform: translateX(-50%);
    color: $highlightcol;
    z-index: 3;
  }
}
.fr .coming_soon {
  &:after {
    content: "À venir!" !important;
  }
}

.langs {
  .cart {
    width: 20px;
    cursor: pointer;
    
    @media screen and (min-width: 1000px) {
      width: 30px;
    }
  }
  // @media screen and (min-width: 1000px) {
  //   position: absolute;
  //   right: 0;
  //   top: 50%;
  //   transform: translateY(-50%);
  // }
  text-align: center;
  // small:first-child {
  //   // padding-right: 2px;
  //   margin-right: 5px;
  // }
  small {
    font-size: 10px;
  }
  a {
    text-decoration: none;
    // background: lightgrey;
    // padding: 5px;
    margin: 5px;
    border-radius: 5px;
    // color: black;
    color: white;
    font-size: 10px;
  }
  // small:last-child {
  //   padding-left: 5px;
  // }
  
  @media screen and (max-width: 999px) {
    padding-top: 15px;
    // width: calc(75% - 20px);
    width: calc(100% / 3);
    margin-left: auto;
    margin-right: 0 !important;
  }
}
.mobile {
  // width: calc(50% - 20px);
  @media screen and (min-width: 1000px) {
    display: none !important;
  }
}

.router-link-exact-active:not(.logolink) {
  color: $highlightcol;
  // @media screen and (min-width: 1000px) {
  //   color: $highlightcol;
  // }
  @media screen and (max-width: 999px) {
    li {
      background: rgb(213, 213, 213);
      border-radius: 10px;
    }
  }
}

.logolink {
  @media screen and (max-width: 999px) {
    text-align: center;
  }
}
.logobar a:not(.langs_a, .logolink, .submitevent a, .back-nav a, .readable_location_outer a) {
    position: relative;
    &:before, &:after {
      content: '';
      position: absolute;
      width: 0%;
      height: 3px;
      background: white;
      bottom: 0;
    }
    .router-link-active {
      width: 100%;
    }
    &:after {
      width: 0%;
      right: 0;
      opacity: 1;
      transition: width .25s ease;
    }
  &:hover {
    &:before {
      content: '';
      width: 100%;
      transition: width .25s ease;
    }
    &:after {
      width: 100%;
      right: 0;
      opacity: 0;
    }
  }
}
.submitevent {
  padding-right: 0;
  a {
    background: $highlightcol;
    border-radius: 10px;
    padding: 10px 20px;
    font-weight: bold;
    cursor: pointer;
    color: white;
  }
  @media screen and (max-width: 999px) {
    display: inline-block;
    a {
      font-size: 10px;
      display: block;
    }
  }
}
.submitForm {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
}
.submitForm-inner {
  transition: all .25s ease;
  opacity: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
  z-index: 10;
  width: calc(100% - 20px);
  height: 80%;
  background: white;
  border-radius: 10px;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-width: 800px;
  box-shadow: $boxshadow;
  label p {
    color: black;
    font-weight: bold;
  }
  h2 {
    padding: 40px 0 20px;
    line-height: 1.25;
    text-align: center;
    margin: auto;
    width: 80%;
    max-width: 400px;
    font-family: "Header";
    color: black;
  }
  span {
    color: red;
  }
  p {
    margin-bottom: 5px;
  }
  form {
    width: 80%;
    padding: 0;
    margin: auto;
  }
  input:not(#img,.submitbutton) {
    width: calc(100% - 20px);
    color: black;
    padding: 10px;
    border: none;
    border-radius: 5px;
    // background: #8f8f8f;
    // border: 1px solid black;
    background: #d0d0d0;
  }
  #img {
      color: black;
  }
  input:focus {
    outline: none;
  }
  .submitbutton {
    background: green;
    color: white;
    margin-top: 30px;
    width: 100%;
    margin-bottom: 30px;
    border:none;
  }
  .close {
    position: absolute;
    right: 10px;
    top: 10px;
    background: black;
    padding: 5px;
    border-radius: 20px;
    width: 30px;
    height:30px;
    margin: auto;
    cursor: pointer;
    h2 {
      margin: auto;
      font-size: $size-subheader;
      padding: 0;
      color: white;
      margin-top: 2px;
    }
  }
}

.fadein-enter-active, .fadein-leave-to {
  opacity: 0;
  transition: all .25s ease;
}
.fadein-enter-to  {
  opacity: 1;
  transition: all .25s ease;
}

.successMessage {
  color: green;
  max-width: 90%;
  text-align: center;
  margin: auto;
}

.captchaMessage {
  color: red;
  max-width: 90%;
  text-align: left;
  margin: 10px 0 20px !important;
}

.readable_location_outer {
  // margin: auto auto auto 20px;
  max-width: 400px;
  // width: 100%;
  text-align: left;
  margin: auto 0;
  background: white;
  border-radius: 20px;


  @media screen and (max-width: 999px) {
    // width: 60% !important;
    text-align: center;
    display: inline-block;
    padding: 0 10px 2.5px 10px;
    border-radius: 0 0 20px 20px;
    // margin-left:20px;
  }

  @media screen and (min-width: 1000px) {
    margin-left: 60px;
    }

  small {
      // width: calc(100% - 25px);
      margin: 0 5px;
      font-family: "BodyBold"; 
      color: black;
      display: inline-block;
      max-width: calc(100% - 25px);
      font-size: $size-smalltext;
      color: black;
      white-space: nowrap;
    }

  .readable_location {
    display: block;
    display: inline-block;
    width: 100%;
    text-align: center;

    @media screen and (min-width: 1000px) {
      // padding: 0px 10px 0px 5px;
      display: flex;
      margin: auto auto auto 0;
      max-width: 300px;
      small {
        line-height: 2;
      }
    }
    @media screen and (max-width: 999px) {
      margin: 2px 0;
      text-align: center;
      // background: rgb(64, 64, 64);
    }

    img, small {
      display: inline-block;
      vertical-align: middle;
      text-align: left;
    }

  }
}

.nolocation {
  background: rgb(96, 96, 96);
  small {
    color: white;
  }
  img {
    filter: invert(1);
    -webkit-filter: invert(1);
  }
}



.back-nav {
  width:calc(100% / 3);
  text-align: left;

  a {
    // background: white;
    border-radius: 10px;
    // padding: 10px 20px;
    -webkit-box-shadow: 0px 9px 14px 0px rgba(0,0,0,0.2); 
    box-shadow: 0px 9px 14px 0px rgba(0,0,0,0.2);

    span {
      // color: black;
      color: white;
      font-size: $size-body;
    }
  }

  .back-nav-inner {
    padding-left: 10px;
  }
  
  @media screen and (max-width: 999px) {
    display: table;
    // position: fixed;
    top: 80px;
    left: 10px;
  }

  .readable_location {
    position: absolute;

    @media screen and (max-width: 999px) {
      max-width: calc(100% / 3);
      top: 50%;
      transform: translateY(-50%);
    }
    @media screen and (min-width: 1000px) {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .chevron {
    font-size: 40px;
    position: absolute;
    top: 50%;
    transform: translateY(-55%);
    display: inline-block;
    vertical-align: middle;
  }
  p {
    margin: 0;
    display: table-cell;
    vertical-align: middle;
    // padding-left: 40px;
  }
  .back {
    display: inline-block;
    margin-left: 20px;
    vertical-align: middle;
  }
  ~ .mobile {
    display: inline-block;
    text-align: right;
    // margin-right: 10px; 
  }
}

.hamburger-menu {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 10;
  background: black;

  .nav {
    text-align: center;
    width: 80vw;

    .submitevent, li {
      padding: 0;
    }
    .submitevent {
      padding-top: 20px;
      a {
        padding: 10px 20px;
      }
    }
  }

  ul {
    position: fixed;
    left: 50vw;
    top: 50vh;
    transform: translateX(-50%) translateY(-50%);
    z-index: 10;

    li {
      list-style: none;
      a {
        font-size: $size-subheader;
      }
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

}

.hamburger-menu-icon {
  width: calc(100% / 3);
  vertical-align: middle;

  .hamburger-icon {
    cursor: pointer;
    width: 30px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    @media screen and (max-width: 999px) {
      margin-right: 10px;
    }
  }
}
</style>
